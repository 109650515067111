import React from 'react';

export function renderGoogleSignInButton() {
    return <div class="g_id_signin" data-type="standard" data-shape="pill" data-theme="filled_blue"></div>;
}

export function setGoogleLoginCallback(redirectUrl) {
    if (typeof __googleLoginCallback !== 'undefined') {
        __googleLoginCallback = (data) => {
            fetch(redirectUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    credential: data.credential
                })
            })
                .then(response => {
                    if (response.redirected) {
                        window.location.href = response.url;
                    } else {
                        console.log('Google login validation failed');
                    }
                })
        };
    }
}
