import React, { useState, useEffect } from 'react';
import { Form, Modal, Button, Grid, Image, Transition, Message } from 'semantic-ui-react';

class TwoFactorAuthentication extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: '',
            visible: true,
            showError: false,
            showErrorText: false,
            canSendAnotherCode: true,
            seconds: 0
        }
        this.timer = 0,
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.startTimer = this.startTimer.bind(this)
        this.countDown = this.countDown.bind(this)
    }


    componentDidUpdate(prevProps, prevState) {
        if(this.props.wrongCode !== prevProps.wrongCode) {
            this.toggleVisibility()
        }
    }

    handleChange(e, { value }) {
        this.setState({ code: value })
    }

    handleSubmit() {
        this.props.handleTwoFactorSubmit(this.state.code)
    }

    startTimer() {
        this.setState({ seconds: 60})
        this.timer = setInterval(this.countDown, 1000)
    }

    countDown() {
        let seconds = this.state.seconds - 1
        this.setState({seconds: seconds})

        if (seconds===0) {
            clearInterval(this.timer)
            this.setState({canSendAnotherCode: true})
        }
    }

    handleResendCodeWithTimer = () => {
        this.handleResendCode()
        this.setState({canSendAnotherCode: false})
        this.startTimer()
        //setTimeout(() => this.setState({canSendAnotherCode: true}), 60000)
    }


    handleResendCode = () => {

        if(!this.state.canSendAnotherCode) {
            return
        }
       // this.setState({canSendAnotherCode: false})

        fetch(rootUrl + 'Login/2fa/Send', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: this.props.account,
                password: btoa(this.props.password)
            })
        })
        .then(res => res.json())
        .then(res => {
            if(res.result===0) {
                //Wrong name or password
            } else {
                /*
                setTimeout(() => {
                    toast(
                        {
                            title: "Koodi lähetetty",
                            type: "success",
                            animation: "bounce",
                            time: 3000
                        }
                    )
                   }, 3000)*/
            }
           //this.setState({canSendAnotherCode: true})

        })
    }

    toggleVisibility() {
        this.setState(prev => ({...prev, visible: !prev.visible, showError: true, showErrorText: true}))
    }

    render() {
       return(
        <div className="ag-content-box">
            <Modal
                dimmer="inverted"
                trigger={
                    <button 
                        className="ui primary button settings-button" 
                        style={{width: '160px'}}
                    >
                    {this.props.actionName}
                    </button>}
                open={true}
            >
                <Modal.Header style={{textAlign: "center"}}>
                    <Image style={{margin: "0 auto", paddingBottom: "1em"}} size="small" src="/Content/AuthLock.png"/>
                    {localization.TwoFactorAuthentication}
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Grid padded centered>
                            <Grid.Row textAlign="center">
                                   <div>
                                       {localization.TwoFactorEmailText1}
                                       <br />
                                       {localization.TwoFactorEmailText2} <span><b>{this.props.email}</b></span>
                                   </div>
                            </Grid.Row>

                            <Grid.Row columns={1}>
                                <Grid.Column mobile={16} tablet={12} computer={8}>
                                    <Transition
                                        visible={this.state.visible}
                                        animation="shake"
                                        duration={500}
                                        onComplete={
                                            () => this.setState({ showError: false })}
                                    >
                                        <Form.Input 
                                            error={this.state.showError}
                                            maxLength="6" 
                                            placeholder={localization.Code} 
                                            value={this.state.code} 
                                            onChange={this.handleChange} 
                                        />
                                    </Transition>
                                    {this.state.showErrorText && (
                                    <Message negative size="small">
                                        <Message.Header>{localization.WrongTwoFactorCode}</Message.Header>
                                        <p>{localization.CheckCode}</p>
                                    </Message>
                                    )}
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row textAlign="center">
                                <span>{localization.TwoFactorEmailText3}{" "}</span>
                                <a style={{ cursor: this.state.canSendAnotherCode ? "pointer" : "not-allowed", color: !this.state.canSendAnotherCode && "gray" }} disabled={!this.state.canSendAnotherCode} onClick={this.state.canSendAnotherCode ? this.handleResendCodeWithTimer : undefined}>{localization.SendNewCode}</a> 
                                <br/>
                                {localization.TwoFactorEmailText4}
                                <br/>
                                {!this.state.canSendAnotherCode && formatText(localization.ResendCode)[0]} {!this.state.canSendAnotherCode ? this.state.seconds : <br/>} {!this.state.canSendAnotherCode && formatText(localization.ResendCode)[1]}
                            </Grid.Row>

                            <Grid.Row textAlign="center">
                                <Button onClick={this.handleSubmit} className="ui circular button piButton" size="huge" disabled={this.props.authButtonDisabled || this.state.code.length < 6}>
                                    <i className="green check icon"></i>
                                    {localization.Send}
                                </Button>
                            </Grid.Row>
                       </Grid>
                    </Form>
                </Modal.Content>
            </Modal>
        </div>
       )
    }
}

export const TwoFactorAuthModal = ({actionName, result}) => {
    const [open, setOpen] = useState(false)
    const [code, setCode] = useState('')
    const [email, setEmail] = useState('')
    const [visible, setVisible] = useState(true)
    const [showError, setShowError] = useState(false)
    const [showErrorText, setShowErrorText] = useState(false)
    const [canSendAnotherCode, setCanSendAnotherCode] = useState(true)

    const toggleVisibility = () => {
        setVisible(prev => !prev)
    }

    const handleOpen = () => {
        setCode('')
        setShowErrorText(false)
        setVisible(true)
        sendCodeToUser()

    }

    const handleCodeChange = (e) => {
        setCode(e.target.value)
    }

    const handleSubmit = () => {
        fetch(globalParameters.rootUrl + "TwoFactorAuth/Email/Confirm", {
            method: "POST", 
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({userCode: code})
        })
            .then(res => res.json())
            .then(res => {
                if(res.result === "Success") {
                    result(true)
                    setOpen(false)
                } else if(res.result === "Invalid Code") {
                    toggleVisibility()
                    setShowError(true)
                    setShowErrorText(true)
                }
            })
    }

    const sendCodeToUserWithTimer = () => {
        sendCodeToUser()
        setCanSendAnotherCode(false)
        //setTimeout(() => setCanSendAnotherCode(true), 60000)
    }

    const sendCodeToUser = () => {
        fetch(globalParameters.rootUrl + "TwoFactorAuth/Email/Send")
         .then(res => res.json())
         .then(res => {
            setEmail(res.email)
         })
         .finally(setOpen(true))
    }

    return (
        <Modal
            trigger={
                <button 
                    className="ui primary button settings-button" 
                    style={{width: '160px'}}
                >
                {actionName}
                </button>}
            onClose={() => setOpen(false)}
            onOpen={handleOpen}
            open={open}
        >
            <Modal.Header>
                <Image style={{margin: "0 auto", paddingBottom: "1em"}} size="small" src="/Content/AuthLock.png"/>
                {localization.TwoFactorAuthentication}
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Grid padded centered>
                        <Grid.Row textAlign="center">
                            <div>
                                {localization.TwoFactorEmailText1}
                                <br />
                                {localization.TwoFactorEmailText2} <span><b>{email}</b></span>
                            </div>
                        </Grid.Row>

                        <Grid.Row columns={1}>
                            <Grid.Column mobile={16} tablet={12} computer={8}>
                                <Transition
                                    animation="shake"
                                    duration={500}
                                    visible={visible}
                                    onComplete={() => setShowError(false)}
                                >
                                    <Form.Input error={showError} maxLength="6" placeholder={localization.Code} value={code} onChange={handleCodeChange} />
                                </Transition>
                                {showErrorText && (
                                    <Message negative size="small">
                                        <Message.Header>{localization.WrongTwoFactorCode}</Message.Header>
                                        <p>{localization.CheckCode}</p>
                                    </Message>
                                )}
                            </Grid.Column>
                        </Grid.Row>
                        
                        <Grid.Row textAlign="center">
                            <span>{localization.TwoFactorEmailText3}</span>
                            <a style={{cursor: canSendAnotherCode ? 'pointer' : 'not-allowed', color: !canSendAnotherCode && 'gray'}} onClick={canSendAnotherCode ? sendCodeToUserWithTimer : undefined}>
                                {localization.SendNewCode}
                            </a> 
                            <br/> 
                            {localization.TwoFactorEmailText4}
                            <br/>
                            {!canSendAnotherCode && formatText(localization.ResendCode)[0]}{!canSendAnotherCode ? <Timer seconds={60} onComplete={() => setCanSendAnotherCode(true)} /> : <br />}{!canSendAnotherCode && formatText(localization.ResendCode)[1]}
                        </Grid.Row>

                        <Grid.Row textAlign="center">
                            <Button onClick={handleSubmit} className="ui circular button piButton" size="huge" disabled={code.length < 6}>
                                <i className="green check icon"></i>
                                {localization.Send}
                            </Button>
                        </Grid.Row>
                    </Grid>
                </Form>
            </Modal.Content>
        </Modal>
    )
}

const Timer = ({ seconds, onComplete }) => {
    const [timeLeft, setTimeLeft] = useState(seconds);
  
    useEffect(() => {
      if (!timeLeft) return onComplete();
  
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, [timeLeft]);
  
    return (
      <>
        {timeLeft}
      </>
    );
  };

const formatText = (text) => {
    const textArray = text.split('0')
    const text1 = textArray[0].slice(0,-1)
    const text2 = textArray[1].substring(1)
    return [text1, text2]
}

export default TwoFactorAuthentication;